import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './strategicalliancespressrelease.scss';

const AlliancesHero = styled.div`
    background-image: url("https://www.meritmile.com/uploads/2019/08/header-strategic-a-bg.jpg");
`

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className={`${pageContext.slug} strategicalliances-wrapper`}>
            <div class="container-fluid">
                <div className="row">
                    <AlliancesHero className="header-container" img={pageContext.acf.header_image.opengraph_image}>
                        <h2 className="d-none">Are Your Strategic Alliances Destined to Boom or Bust</h2>
                    </AlliancesHero>
                </div>
            </div>
            
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="first-paragraph">
                            <h3 className="text-center"><strong>New Merit Mile Research Shows How A Lack of Dedicated Internal Marketing Resources Can Impede Strategic Alliance Results for Organizations </strong></h3>
                            <p>&nbsp;</p>
                            <p><strong>BOCA RATON Fla., (August 1, 2019)</strong> – Merit Mile, a full-service Boca Raton-based advertising and public relations firm, released today the results of its latest market research, with trends showing how enterprise and mid-level organizations are prevented from maximizing strategic alliance partner initiatives due to lack of support from internal marketing teams. Click here to <a href="https://meritmile.com/technology-marketing/strategic-alliances-2019-trends-infographic/?utm_source=press-release&amp;utm_medium=PR&amp;utm_campaign=strategic-alliances&amp;utm_content=mmsite">download the Strategic Alliances 2019 Marketing Trends infographic</a>.</p>
                            <p>Merit Mile’s research division commissioned an online survey during June, 2019, and collected responses from nearly 150 executives in charge of strategic alliance initiatives for their organizations. Strategic alliances are highly sought by C-Level executives within enterprise-level and mid-level businesses to maximize and advance complementary product and brand offerings. Merit Mile’s research has found that while half of those polled (51%) said their organization is currently involved with between six to ten strategic alliances, 64.4% of respondents said they struggle with a lack of internal marketing resources to create content, or a lack of access to internal subject-matter experts, product managers or product marketing managers.</p>
                            <p>“Everywhere you look today, there are cross-promotional brand initiatives that complement enterprise organizations and benefit consumers, such as Citrix, Google Cloud, Microsoft, and T-Systems (a subsidiary of Deutsche Telekom), said Mark Reino, CEO and founder of Merit Mile. “C-Level executives invest millions of dollars into bringing these partnerships to life, but these investments are not being maximized due to a lack of internal marketing support from teams that simply do not have the necessary bandwidth to serve creative, marketing and content initiatives.”</p>
                            <p>More than half of respondents (54%) expect the number of strategic partnerships to either remain at the same level or increase over the next twelve months. It is in the executive suite’s best interest to find a way to maximize their investment into their strategic partnership programs since 41% of respondents said at least 10% of the organization has a hand in these initiatives. What’s more, 37% said at least a quarter of their marketing budget is devoted to these initiatives. Another 53% said their budgets would either remain steady or even increase in the coming year for partnership programs.</p>
                            <p>Proper marketing support is critical for these programs, with 37% saying their most important goals are increasing sales opportunities, followed by increasing the top of the sales funnel (36%). In terms of the key metrics they use to define this success, 38% said co-funding marketing funds for programs, and 30% said increasing mindshare of programs through earned media and program awareness.</p>
                            <p>Agency support is a potentially critical ally of support for these organizations, as agencies can serve as strategic partners and additional headcount for execution of programs. In fact, half of respondents (51%) said they’ve worked with an agency on strategic alliance marketing over the last two years, and 54% said they saw a noticeable and measurable increase in organizational thought leadership and program awareness.</p>
                            <p>Click here to <a href="https://meritmile.com/technology-marketing/strategic-alliances-2019-trends-infographic/?utm_source=press-release&amp;utm_medium=PR&amp;utm_campaign=strategic-alliances&amp;utm_content=mmsite">download the Strategic Alliances 2019 Marketing Trends infographic</a>.</p>
                        </div>
                    </div>
                    <div classNameName="col-12">
                        <div className="second-paragraph"></div>
                        <div className="third-paragraph"></div>
                    </div>
                </div>
            </div>

        </div>
    </Layout>
)